import React from 'react';
import styled from 'styled-components';

const Container404 = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContainerTitle = styled.h1`
  font-size: 9rem;
  color: #fff;
  margin: var(--spacing-xxl) auto var(--spacing-xl) auto;
  text-align: center;
  font-weight: bold;
  font-family: var(--font-large);
  text-indent: -0.1rem;
`;

const ContainerTitleInverse = styled.span`
  font-size: 9rem;
  color: var(--secondary-color);
  margin: var(--spacing-xxl) auto;
  text-align: center;
  font-weight: bold;
  font-family: var(--font-large);
  text-indent: -0.1rem;
`;

const ContainerSubTitle = styled.p`
  color: var(--secondary-color);
  margin: 0 auto var(--spacing-xxl) auto;
  font-size: 1rem;
  font-family: var(--font-small);
  font-weight: normal;

  a {
    color: var(--secondary-color);
  }
`;

export default function FourOhFourPage() {
  return (
    <Container404>
      <ContainerTitle>
        4<ContainerTitleInverse>.</ContainerTitleInverse>0
        <ContainerTitleInverse>.</ContainerTitleInverse>4
      </ContainerTitle>
      <ContainerSubTitle>
        Breng mij{' '}
        <a href="/" title="Day By Day">
          terug
        </a>{' '}
        naar de homepage
      </ContainerSubTitle>
    </Container404>
  );
}
